import './App.css';
import Home from './components/Home'
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import BrokerCheck from './components/BrokerCheck';
import Services from './components/Services';
import Footer from './components/Footer'
import Resources from './components/Resources';
import ContactUs from './components/ContactUs';
import AboutPage from './components/AboutPage'
import Process from './components/Process';
import ScrollToTop from './components/ScrollToTop'

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/services' component={Services}/>
          <Route exact path='/resources/calculators' render={() => <Resources resource='Calculators' />}/>
          <Route exact path='/resources/tools' render={()=> <Resources resource='Tools' />} />
          <Route exact path='/resources/personal-finance' render={()=><Resources resource='Personal Finance' />}/>
          <Route exact path='/resources/helpful-links' render={()=><Resources resource='Helpful Links' />}/>
          <Route exact path='/contact' component={ContactUs}/>
          <Route exact path='/about' component={AboutPage}/>
          <Route exact-path='/process' component={Process}/>
        </Switch>
        <Footer/>
        <BrokerCheck/>
      </Router>
    </div>
  );
}

export default App;
