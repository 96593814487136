import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'

export default class Tools extends React.Component{

    render(){
        return <Container>
<br></br>
            <p>Here are some great financial resources to keep you as informed as possible.</p>
<br></br>
<hr></hr>
            <Row>
                <Col>
                    <a target='_blank' rel="noopener noreferrer" href='https://tools.finra.org/fund_analyzer/'><h6>Fund Analyzer</h6></a>
                    <p>Automatically compare fees and analyze information on over 30,000 mutual funds, exchange-traded funds, exchange-traded notes and money market funds.</p>
                    <br></br>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.ssa.gov/benefits/retirement/'><h6>Retirement Estimator</h6></a>
                    <p>Estimate your actual Social Security earnings record.</p>
                </Col>
                <Col>
                    <a target='_blank' rel="noopener noreferrer" href='http://finra-markets.morningstar.com/MarketData/Default.jsp?sdkVersion=2.59.0'><h6>FINRA Market Data</h6></a>
                    <p>Find market information including stock quotes and information about corporate, municipal, treasury and agency bonds.</p>
                    <br></br>
                    <a target='_blank' rel="noopener noreferrer" href='https://tools.finra.org/scam_meter/'><h6>Scam Meter</h6></a>
                    <p>With just four questions our Scam Meter will help you tell if an investment opportunity might be a scam.</p>
                </Col>
            </Row>
<hr></hr>
<br></br>
            <p><strong>Link Disclosure:</strong> This information is being provided strictly as a courtesy.  When you link to any of the websites provided you are actually leaving this website.  We make no representation as to the completeness or accuracy of the information provided on these websites nor is the company liable for any direct or indirect technical or system issues or any consequences arising out of your access to or use of these third-party technologies, websites, information or programs made available through them.</p>
<br></br>
<br></br>
            <h6>Source: FINRA - finra.org</h6>
        </Container>
    }
}