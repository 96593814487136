import React from 'react'
import PageHeader from './PageHeader'
import {Container, Image, Row, Col, Button} from 'react-bootstrap'
import discovery from '../images/discovery.jpg'
import FinPlanningStock from '../images/FinPlanningStock.jpg'
import investments from '../images/investments.jpg'
import review from '../images/review.jpg'
import { Reveal, Tween } from 'react-gsap';
import {Link} from 'react-router-dom'


const FadeInLeft = ({ children }) => (
    <Tween
      from={{ opacity: .5, transform: 'translate3d(-50vw, 0, 0)' }}
      ease="back.out(0)"
    >
      {children}
    </Tween>
  );

  const FadeInRight = ({ children }) => (
    <Tween
      from={{ opacity: .5, transform: 'translate3d(50vw, 0, 0)' }}
      ease="back.out(0)"
    >
      {children}
    </Tween>
  );

export default class Process extends React.Component{
    render(){
        return <div>
            <PageHeader title='Our Process'/>
            <br></br>
            <br></br>
          <Container className='process' >
<br></br>
            <h5>Proper financial planning requires a diligent process.  T.F. has spent over 20 years perfecting his process to help better serve his clients. </h5>
<br></br>
<br></br>
<hr></hr>
            <br></br>
            <Row>
              <Col lg={true}>
                <Image src={discovery} alt='Introduction Meeting' width='592px' height='350px' className='shadow process-img'/>
              </Col>
              <Col lg={true}>
                <h3>1.) Discovery Meeting</h3>
                <p>This stage is all about getting to know each other.  T.F. will take the time to listen and better understand you and your personal dreams and goals.  We will then break down each item and address where you are now and how close or far away your goals may be.</p>
              </Col>
            </Row>
<br></br>
<br></br>
            <Reveal repeat trigger={<div />}>
              <FadeInLeft>
                <Row>
                  <Col lg={true}>
                    <h3> 2.) Planning Phase</h3>
                    <p>Now that he knows more about you and your unique situations, we can move on to formulating a plan.  We will talk about your investments, protection and other opportunities.  Once we establish a plan, we can refer back to make sure we are staying on track.</p>
                  </Col>
                  <Col lg={true}>
                    <Image src={FinPlanningStock} alt='Planning' width='592px' height='350px' className='shadow process-img'/>
                  </Col>
                </Row>
              </FadeInLeft>
            </Reveal>
<br></br>
<br></br>
            <Reveal repeat trigger={<div />}>
              <FadeInRight>
                <Row>
                  <Col lg={true}>
                    <Image src={investments} alt='Investments' width='592px' height='350px' className='shadow process-img'/>
                  </Col>
                  <Col lg={true}>
                    <h3>3.) Investing</h3>
                    <p>This is when we begin to talk about investments.  T.F. will walk you through multiple proposals that will help you reach your goals.  These proposals will take into consideration all the information you shared from step one and step two.  T.F. will make it a point to explain each investment thoroughly as he wants to make sure you are confident and know exactly the purpose of your investments and how they will work for you. </p>
                  </Col>
                </Row>
              </FadeInRight>
            </Reveal>
<br></br>
<br></br>
            <Reveal repeat trigger={<div />}>
              <FadeInLeft>
                <Row>
                  <Col lg={true}>
                    <h3>4.) Adaptation</h3>
                    <p>Our plan is now in place and in action.  T.F. will make sure to remain in contact with you to make sure our plan stays on track.  As life ebbs and flows so will your plan.  Our regular reviews will ensure we are constantly adapting and adjusting.</p>
                  </Col>
                  <Col lg={true}>
                    <Image src={review} alt='Adjustments' width='592px' height='350px' className='shadow process-img'/>
                  </Col>
                </Row>
              </FadeInLeft>
            </Reveal>
<br></br>
<br></br>
          </Container>            
          <Container className='process-contact'>
            <br></br>
            <hr></hr>
            <br></br>
            <p>T.F. uniquely developed this process to assure clients are served in the way they want to be served.  This process may take multiple appointments or be done by phone.  The timeline is completely up to the client.  Contact us now to schedule an appointment. </p>
<br></br>
          <Link to='/contact'>
            <Button size='lg' className='hvr-wobble-vertical'>Contact Us</Button>
          </Link>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </Container>
        </div>
    }
}