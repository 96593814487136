import React from 'react'
import {Container, Image, Row, Col, Button} from 'react-bootstrap'
import '../App.css';
import {ClipboardData, GraphUp, HouseDoor, ChevronDoubleDown } from 'react-bootstrap-icons'
import NavBar from '../components/NavBar'
import WMOLogoTransparent from '../images/WMOLogoTransparent.png'
import ContactUs from '../components/ContactUs'
import ReactCardFlip from 'react-card-flip';
import {Tween, ScrollTrigger, SplitWords} from 'react-gsap';
import TFHeadshot from '../images/TFHeadshot.jpg';
import {Link} from 'react-router-dom'

export default class Home extends React.Component{

    constructor(props){
        super(props)
        this.myDivToFocus = React.createRef()
        this.state = {
            flipFin: false,
            flipRet: false,
            flipEst: false
        }
    }

    handleFlipFin = (event)=>{
        event.preventDefault()
        
        let flip = this.state.flipFin
        this.setState({
            flipFin: !flip
        })
    }

    handleFlipRet = (event)=>{
        event.preventDefault()
        
        let flip = this.state.flipRet
        this.setState({
            flipRet: !flip
        })
    }

    handleFlipEst = (event)=>{
        event.preventDefault()
        
        let flip = this.state.flipEst
        this.setState({
            flipEst: !flip
        })
    }


    handleClick=(event)=>{
        if(this.myDivToFocus.current){
            this.myDivToFocus.current.scrollIntoView({
                behavior: 'smooth',
            })
        }
    }

    render(){
        return <div>
            <header className='banner-container' >
                <Container fluid className='home-nav-container' >
                <NavBar/>
                </Container>
<br></br>
<br></br>
<Container fluid >
    <Row className="justify-content-md-center">
               <div className='behind-cover-logo'>
                    <img src={WMOLogoTransparent} alt="logo"  className='header-logo' />
                </div>
                </Row>
                
                
                {/* <h1><strong>Wealth Management of the Ozarks</strong></h1> */}
<br></br>
<Row className="justify-content-md-center">
            <div className='learn-more'>
                <h6>LEARN MORE</h6>
                <span className='hvr-hang' onClick={this.handleClick}><ChevronDoubleDown width='40px' height='40px'/></span>
            </div>
            </Row>
            </Container>
            
            </header>
            
            <Container className='home-about' fluid ref={this.myDivToFocus}>
                <br></br>
                <br></br>
                <Container>
                {/* <ScrollTrigger start="-250px center" end="50px center" scrub={1} >

                <Tween from={{ x: '200px' }} stagger={1} ease="elastic.out(0.1, 0.1)">
<SplitWords wrapper={<div style={{ display: 'inline-block', fontSize: '40px' }} />}>
                    About Wealth Management of the Ozarks
                    </SplitWords>
                    </Tween>
                    </ScrollTrigger> */}

{/* <br></br> */}
{/* <br></br> */}
<Row className="justify-content-md-center">

    <Col lg={true}>
    <ScrollTrigger start="-250px center" end="50px center" scrub={1} >

<Tween from={{ x: '200px' }} stagger={1} ease="elastic.out(0.1, 0.1)">
<SplitWords wrapper={<div style={{ display: 'inline-block', fontSize: '40px' }} />}>
    About Wealth Management of the Ozarks
    </SplitWords>
    </Tween>
    </ScrollTrigger>

<br>
</br>
<br></br>
    Welcome to <strong>Wealth Management of the Ozarks</strong>, a financial practice specializing in retirement planning and estate conservation. You will find a wealth of information about our capabilities and how we can help you reach your financial goals.

    As personal financial professionals, we can:

<br></br>
<br></br>
                        <li>Help you clarify and define your goals</li>
                        <li>Recommend strategies designed to fit your needs</li>
                        <li>Deliver timely information</li>
                        <li>Explain how changing financial conditions affect you</li>
                        <li>Monitor your financial progress</li>
                        <li>Help make investment planning easier</li>
<br></br>
Our website is an information resource designed to help you learn more about personal finance.  We hope your visit will help you understand the opportunities and potential rewards that become available when you take a proactive approach to your personal financial situation. You will find a variety of articles, calculators, and research reports that we hope will answer your questions or stimulate your interest. 


    </Col>
    <Col md='auto' className='home-about-img'>
        <Link to='/about'>
        {/* <a href='/about'> */}
    <Image src={TFHeadshot} alt='T.F. White Headshot' width='300px' style={{borderRadius: '100%'}}/>
    {/* </a> */}
    </Link>
    <h3>T.F. White, Jr. <a href='https://www.investopedia.com/terms/a/accredited-asset-management-specialist-aams.asp' target='_blank' rel="noopener noreferrer">AAMS®</a></h3>
    <p>President
        <br></br>
        Wealth Advisor

    </p>
<br></br>

    </Col>
</Row>
                    {/* Welcome to <strong>Wealth Management of the Ozarks</strong>, a financial practice specializing in retirement planning and estate conservation. You will find a wealth of information about our capabilities and how we can help you reach your financial goals.
<br></br>
<br></br>
                    As personal financial professionals, we can:

<br></br>

                        <li>Help you clarify and define your goals</li>
                        <li>Recommend strategies designed to fit your needs</li>
                        <li>Deliver timely information</li>
                        <li>Explain how changing financial conditions affect you</li>
                        <li>Monitor your financial progress</li>
                        <li>Help make investment planning easier</li>
<br></br> */}

                    {/* Our website is an information resource designed to help you learn more about personal finance.  We hope your visit will help you understand the opportunities and potential rewards that become available when you take a proactive approach to your personal financial situation. You will find a variety of articles, calculators, and research reports that we hope will answer your questions or stimulate your interest.  */}
                </Container>
            </Container>
<br></br>
<br></br>
            <Container fluid className='speacialties'>
                <h1>Our Specialties</h1>
<br></br>
<br></br>
                <Row xs={1} lg={3} >
                    <Col style={{paddingTop: '50px', paddingBottom: '50px'}}>
                    {/* <Reveal repeat trigger={<div />}> */}
                    <ScrollTrigger start="-100px center" end="50px center" scrub={1} >
                <Tween from={{ x: '-1000px', rotation: 360 }} duration={2} ease="back.out(0)">
<div>
                        <ReactCardFlip isFlipped={this.state.flipFin} flipDirection="vertical" >
                            <Container onClick={this.handleFlipFin} >
                                <GraphUp width='200px' height='200px' name='graphfin' style={{color: '#e77725'}}/>
                                <br></br>
                                <br></br>
                                <h2>Financial Planning</h2>
                                <br></br>
                                <Button >Learn More</Button>
                            </Container>
                            <Container onClick={this.handleFlipFin}>
                                <GraphUp width='100px' height='100px'/>
                                <h4>Financial Planning</h4>
                                <p>With over 20 years of professional market experience, T.F. has the wisdom to navigate the markets no matter what your financial goals may be.  We design financial plans to ensure that every aspect of your wealth is covered.  This is a process that many clients learn to enjoy.  Contact us to schedule an in-depth financial plan.</p>
                            </Container>
                      
                        </ReactCardFlip>
                        </div>
                        </Tween>
                    </ScrollTrigger>
                    </Col>
                    

                    <Col  style={{backgroundColor: '#DCDCDC', paddingTop: '50px', paddingBottom: '50px', width: '100%'}}>
                    <ScrollTrigger start="-175px center" end="50px center" scrub={1} >
                <Tween from={{ x: '-1000px', rotation: 360 }} duration={2} ease="back.out(0)">
<div>
                        <ReactCardFlip isFlipped={this.state.flipRet} flipDirection='vertical'>
                            <Container onClick={this.handleFlipRet}>
                                <ClipboardData width='200px' height='200px' style={{color: '#e77725'}}/>
                                <br></br>
                                <br></br>
                                <h2>Retirement Planning</h2>
                                <br></br>
                                <Button   >Learn More</Button>
                            </Container>
                            <Container onClick={this.handleFlipRet}>
                            <ClipboardData width='100px' height='100px'/>
                            <h4>Retirement Planning</h4>
                            <p>Retirement Planning is one of the single most important aspects to preserving and enjoying your wealth. The common misconception is to build your wealth to a certain amount and then you will no longer need to work.  While this is part of the retirement equation, it skips over many crucial factors.  T.F.’s approach to retirement planning covers things you probably have not thought of.  Contact us today, to schedule a retirement planning consultation. </p>
                            </Container>


                        </ReactCardFlip>
                        </div>
                        </Tween>
                    </ScrollTrigger>
                        </Col>
                    
                    <Col style={{paddingTop: '50px', paddingBottom: '50px'}}>
                    <ScrollTrigger start="-250px center" end="50px center" scrub={1} >
                <Tween from={{ x: '-1000px', rotation: 360 }} duration={2} ease="back.out(0)">
<div>

                    <ReactCardFlip isFlipped={this.state.flipEst} flipDirection='vertical'>
                        <Container onClick={this.handleFlipEst}>
                            <HouseDoor width='200px' height='200px' style={{color: '#e77725'}}/>
                            <br></br>
                            <br></br>
                            <h2>Estate Planning</h2>
                            <br></br>
                            <Button >Learn More</Button>
                        </Container>
                        <Container onClick={this.handleFlipEst}>
                        <HouseDoor width='100px' height='100px'/>

                        <h4>Estate Planning</h4>
                        <p>For some people, financial planning mainly consists of learning how to build a nest egg.  But for many, there is a desire to ensure their estate delivers benefits beyond their own retirement to produce a lasting legacy.  T.F. utilizes  years of experience and strategic partners to insure your legacy continues  exactly as you want it to.  Contact us today to schedule an estate planning consultation. </p>

                        </Container>
                    </ReactCardFlip>
                    </div>
                        </Tween>
                    </ScrollTrigger>

                    </Col>
                </Row>
<br></br>
<br></br>
            </Container>








            <Container fluid style={{backgroundColor: '#DCDCDC'}}>
<br></br>
<br></br>
                <ContactUs home='true'/>
<br></br>
            </Container>      
        </div>
    }
}