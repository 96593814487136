import React from 'react'
import { Form, Row, Col,Image, Container, Button, Alert } from 'react-bootstrap'
import WMOLogoTransparent from '../images/WMOLogoTransparent.png'
import PageHeader from './PageHeader'
import emailjs from 'emailjs-com';

export default class ContactUs extends React.Component{

    state={
        name: '',
        email: '',
        phone: '',
        message: '',
        validated: false,
        showSucess: false,
        showLoading: false,
        showError: false
    }

    handelChange = (event)=>{
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handelSubmit=(event)=>{
        event.preventDefault()
        console.log(this.state.name, this.state.email, this.state.phone, this.state.message)
    }

    sendEmail = (e) => {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        console.log('fill in the stuff')
      }

      this.setState({
        validated: true
      })

      if(form.checkValidity() === true){
        e.preventDefault()
        console.log('sending email...', this.state.validated)
        this.setState({
            showLoading: true
        })

        emailjs.sendForm('service_rrl5vzf', 'contact_form', e.target, 'user_41oPFOX8J0CIxOSN5u16e')

        .then((result) => {
            console.log(result.text);
            this.setState({
                showLoading: false,
                showSucess: true
            })
        }, (error) => {
            this.setState({
                showLoading: false,
                showError: true
            })
            console.log(error.text);
        });
      }
    }

    render(){
        return <div>
          {this.props.home==='true'? <h1>Contact Us</h1>: <PageHeader title='Contact Us'/>}
          <br></br>
          <Container>
            <Alert show={this.state.showSucess} variant="success">
              <Alert.Heading>Message Successfuly Submitted</Alert.Heading>
              <p>
                T.F. will recieve you message shortly. He will review your message and reach out to you via email or phone, but feel free to call the office at (479) 401-2525 if you need to talk to him sooner. If you need to send a second message please refresh the page. Thank you for reaching out, he will be in touch soon!
              </p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() =>  window.location.assign('/')} variant="outline-success">
                  Back to Home Page
                </Button>
              </div>
            </Alert>

            <Alert show={this.state.showLoading} variant="warning">
              <Alert.Heading>Sending Message Now...</Alert.Heading>
              <p>
                  Hold tight... We are sending your message now. Please do not close or refresh the page.
              </p>
              <hr/>
            </Alert>

            <Alert show={this.state.showError} variant="danger">
              <Alert.Heading>Error Sending Message</Alert.Heading>
              <p>
                  There was an error sending your message. Please refresh the page and try again. If problems continue, please reach out to T.F. White at (479)-401-2525 or T.F.White@lpl.com. Sorry for the inconvenience.
              </p>
              <hr/>
              <div className="d-flex justify-content-end">
                <Button onClick={() =>  window.location.assign('/contact')} variant="outline-success">
                  Refresh Page
                </Button>
              </div>
            </Alert>

            <Row xs={1} lg={2}>
                <Col >
                  <br></br>
                  <strong><p>Send Us a Message</p></strong>
                  <p>Please complete the Contact Form and we'll be in touch.</p>
                  <Form onSubmit={this.sendEmail} noValidate validated={this.state.validated}>
                    <Form.Row>
                      <Col>
                        <Form.Group controlId="validationCustom01">
                          <Form.Control type='text' placeholder='*Name' size="lg" name='name' onChange={this.handelChange} required/>
                          <Form.Control.Feedback type="invalid">
                            Please enter name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <Form.Group>
                          <Form.Control type='email' placeholder='*Email' size="lg"  name='email' onChange={this.handelChange} required/>
                          <Form.Control.Feedback type="invalid">
                            Please provide an email.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Control type='number' placeholder='Phone' size="lg" name='phone' onChange={this.handelChange}/>
                      </Col>
                    </Form.Row>
                    <Form.Group>
                      <Form.Control as='textarea' rows={3} placeholder='*Write Your Message' size="lg" name='message' onChange={this.handelChange} required/>
                      <Form.Control.Feedback type="invalid">
                        Please enter a message for TF White. 
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit" block >
                      Submit
                    </Button>
                  </Form>
                </Col>
                <Col>
                <br></br>
                <Image src={WMOLogoTransparent} width='50%'/>
                <p>1200 W Commercial St.
                  <br></br>
                  Ozark, AR 72949
                </p>
                <p>Phone: (479) 401-2525</p>
                <p>
                  www.theozarkswealth.com
                  <br></br>
                  T.F.White@lpl.com
                </p>
                </Col>
            </Row>
          </Container>
            <br></br>
            <br></br>
        </div>
    }
}