import React from 'react'
import {Link} from 'react-router-dom'
import {Nav, Navbar, Button, NavDropdown} from 'react-bootstrap'
import WMOLogoTransparent from '../images/WMOLogoTransparent.png'
import LogoSybmol from '../images/LogoSymbol.png'
import {Justify} from 'react-bootstrap-icons'

export default class NavBar extends React.Component{


    renderLogo = ()=>{
        if(this.props.home === 'false'){
          return <Link to='/'>
            <div className='behind-transparent-logo'>
                <img src={WMOLogoTransparent} alt="logo" className='banner-logo'  />
            </div> 
          </Link> 
        } else{
            return <div>
              <Link to='/'>
                <img src={LogoSybmol} alt="logo" className='nav-logo-sybmol'  />
              </Link>
            </div>
        }
    }

    renderHome = () =>{
      if(this.props.home === 'false'){
        return <Nav.Item>
        <Link to='/' sytle={{paddingRight:'10px'}}>Home</Link>{' '}
      </Nav.Item>

      } 
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
      }

      componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
      }

      handleScroll = () => {
        if(document.querySelector('.scroll') == null){
          if (window.scrollY > 1) {
            document.querySelector(".home-nav").className = "home-nav navbar navbar-expand-lg navbar-light fixed-top scroll";
          } else {
            document.querySelector(".home-nav").className = "home-nav navbar navbar-expand-lg navbar-light";
          }
        } else{
          if(window.scrollY===0){
            document.querySelector(".home-nav").className = "home-nav navbar navbar-expand-lg navbar-light fixed-top";
          }
        }
      };

      handleClick = (event)=>{
        document.querySelector(".home-nav").className = "home-nav navbar navbar-expand-lg navbar-light fixed-top scroll";
      }
    
    render(){
        return <Navbar  className='home-nav'  expand='lg' fixed='top'>
            <Navbar.Brand>
                {this.renderLogo()}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.handleClick}>
              <Justify style={{color: '#e77725'}}/> 
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav" onClick={this.handleClick}>
              <Nav className='mr-right'>
                {this.renderHome()}
                <Nav.Item>
                  <Link to='/services'>Services</Link>{' '}
                </Nav.Item>

                <NavDropdown title="Resources" id="basic-nav-dropdown" style={{paddingRight: '5px'}}>
                  <Link to='/resources/calculators'><div className='dropdown-item'>Calculators</div></Link>
                  <Link to='/resources/tools'><div className='dropdown-item'>Tools</div></Link>
                  <Link to='/resources/personal-finance'><div className='dropdown-item'>Personal Finance</div></Link>
                  <Link to='/resources/helpful-links'><div className='dropdown-item'>Helpful Links</div></Link>
                  <NavDropdown.Item href='https://www.lpl.com/' target='_blank' rel="noopener noreferrer">LPL</NavDropdown.Item>
                </NavDropdown>

                <Nav.Item>
                  <Link to='/contact'>Contact Us</Link>{' '}
                </Nav.Item>

                <Nav.Item>
                  <Link to='/process'>Process</Link>{' '}
                </Nav.Item>

                <Nav.Item>
                  <Link to='/about'>About</Link>{' '}
                </Nav.Item>

                {/* <NavDropdown title='About' id='basic-nav-dropdown' style={{paddingRight: '5px'}}>
                  <NavDropdown.Item href='/process'>Our Process</NavDropdown.Item>
                  <NavDropdown.Item href= '/about' style={{paddingRight: '5px'}}>About Me</NavDropdown.Item>
                </NavDropdown> */}

                <Nav.Item>
                  <a href='https://myaccountviewonline.com/AccountView/'><Button className='login-btn' variant="outline-warning" style={{padding: '2px', fontSize:'20px', marginLeft: '10px'}}>Client Login</Button></a>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
    }
}