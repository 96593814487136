import React from 'react'
import {Container} from 'react-bootstrap'
import PageHeader from './PageHeader'
import AccordionService from './AccordionService'

export default class Services extends React.Component{

    render(){
        return <div>
            <PageHeader title='Our Services' />
            <Container>
                <br></br>
                <br></br>
                <p>Wealth Management of The Ozarks provides a wealth of services to ensure clients are covered for the 'ifs' in life. Every client is unique and so is their financial plan. T.F. has a time tested process for each of the services listed below. T.F. also realizes that not everyone needs every service. He always takes time in the discovery meeting to determine how you want to be served. This insures that you are getting the help you want and not being upsold some package of services that is not important to you.</p>
                <br></br>
                <hr></hr>
                <br></br>
                <AccordionService/>
                <br></br>
                <br></br>
            </Container>
        </div>
    }
}