import React from 'react'
import { Container, Image, Row, Col } from 'react-bootstrap'
import PageHeader from './PageHeader'
import TFHeadshot from '../images/TFHeadshot.jpg'

export default class AboutPage extends React.Component{

    render(){
        return <div>
            <PageHeader title='About T.F. White Jr.' />
            <br></br>
            <br></br>
            <Container fluid className='about-bio'>
                <Row className="justify-content-md-center">
                    <Col md='auto' style={{alignItems: 'center', margin: 'auto', paddingRight: '0px'}} className='bio-img'>
                        
                        <Image src={TFHeadshot} alt='TF White Headshot' width="300px" style={{margin: 'auto'}}/>
                        <br></br>
                        <h3>T.F. White, Jr. <a href='https://www.investopedia.com/terms/a/accredited-asset-management-specialist-aams.asp' target='_blank' rel="noopener noreferrer">AAMS®</a></h3>
    President
        <br></br>
        Wealth Advisor


                    </Col>
                    <Col>
                        <Container style={{border: 'solid', padding: '30px', borderColor: 'lightgray'}} className='shadow'>
                            <p>T. F. White, Jr., has been in Financial Services since 2000 allowing for over twenty years of industry experience. Whether it be during your wealth accumulation, maintenance, or wealth distribution, there are unique needs and concepts that can and should be addressed.  Considerations for tax treatment, protection from the unknown, or wealth transfer must also be in the equation.  All of these aspects and more should be included in your wealth management plan. Whether working with individuals, families, and/or businesses, T.F. realizes that his highest objective is to create sound solutions to help investors pursue their goals.</p>
                            <p>T.F. and Wealth Management of the Ozarks, LLC are now ready to do just that.  T.F. will now have more solutions and opportunities available to provide even more tailored plans for his clients.  As before, small town service and being here when you need us are standard in our practice.  We realize that financial concerns as well as opportunities do not always happen between the hours of 8-5.  Whether it is in the office, out in the community, or in your home we will be here when you need us!</p>
                            <p>T.F.’s roots are here in the foothills of the Ozarks.  From growing up on a small farm in Ozark and being back on that same farm years later, this is his home.  T.F. graduated with a Bachelor of Science ('84) and Masters of Science in Economics ('85) from the University of Arkansas-Fayetteville.  He went on to obtain a Doctor of Philosophy from Auburn University ('88) and was an Economist for the USDA in Washington D.C. before returning back home to Arkansas in 1993 to help his father with the family farm.  In his time away from the office,  T.F. enjoys his family, farm, and the outdoors.  He has earned his FINRA Series 7, SIE, 63, and 66 registrations which are currently held by LPL Financial.  T.F. also holds life and health Insurance licenses.  He retains AAMS certification from the College for Financial Planning and has since 2006.</p>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <br></br>
            <br></br>
        </div>
    }
}