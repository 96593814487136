import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
export default class Helpful extends React.Component{

    render(){
        return <div>
            <Container>
                <br></br>
<hr></hr>
                <Row>
                    <Col lg={true}>
                        <h2>Taxes:</h2>
                        <a target='_blank' rel="noopener noreferrer" href='https://www.irs.gov/'><h6>Internal Revenue Service</h6></a>
                        <a target='_blank' rel="noopener noreferrer" href='https://www.irs.gov/forms-instructions'><h6>Tax Forms and Publications</h6></a>
                    </Col>

                    <Col lg={true}>
                        <h2>Government:</h2>
                        <a target='_blank' rel="noopener noreferrer" href='https://www.ssa.gov/'><h6>Social Security Administration</h6></a>
                        <a target='_blank' rel="noopener noreferrer" href='https://www.medicare.gov/'><h6>Medicare</h6></a>
                    </Col>
                    
                    <Col lg={true}>
                        <h2>News:</h2>
                        <a target='_blank' rel="noopener noreferrer" href='https://www.wsj.com/'><h6>The Wall Steet Journal</h6></a>
                        <a target='_blank' rel="noopener noreferrer" href='https://www.marketwatch.com/'><h6>Market Watch</h6></a>
                        <a target='_blank' rel="noopener noreferrer" href='https://finance.yahoo.com/'><h6>Yahoo Finance</h6></a>
                        <a target='_blank' rel="noopener noreferrer" href='https://www.lpl.com/newsroom.html'><h6>LPL News</h6></a>
                    </Col>
                    
                    <Col lg={true}>
                        <h2>Entities:</h2>
                        <a target='_blank' rel="noopener noreferrer" href='https://www.sipc.org/'><h6>SPIC</h6></a>
                        <a target='_blank' rel="noopener noreferrer" href='https://www.finra.org/#/'><h6>FINRA</h6></a>
                        <a target='_blank' rel="noopener noreferrer" href='https://www.lpl.com/'><h6>LPL</h6></a>


                    </Col>
                </Row>
<hr></hr>
<br></br>
                <Row>
                    <Col>
                        <p><strong>Link Disclosure:</strong> This information is being provided strictly as a courtesy.  When you link to any of the websites provided you are actually leaving this website.  We make no representation as to the completeness or accuracy of the information provided on these websites nor is the company liable for any direct or indirect technical or system issues or any consequences arising out of your access to or use of these third-party technologies, websites, information or programs made available through them.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    }
}