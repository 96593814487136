import React from 'react'
import {Container, Col, Row} from 'react-bootstrap'

export default class Footer extends React.Component{
    render(){
        return <Container className='footer' fluid>
            <br></br>
            <Row>
                <Col md="auto">
                    <u><strong>Contact</strong></u>
                    <br></br>
                    Phone: (479) 401-2525
                    <br></br>
                    Email: T.F.White@lpl.com
                    <br></br>
                    Arkansas Insurance: #3651923
                    <br></br>
                    <br></br>
                    Wealth Management of the Ozarks
                    <br></br>
                    1200 W Commercial St
                    <br></br>
                    Ozark, AR 72949
                </Col>

                <Col md="auto">
                    <u><strong>Quick Links</strong></u>
                    <br></br>
                    <a style={{color: 'white'}} target='_blank' rel="noopener noreferrer" href='https://www.finra.org/#/'>FINRA</a>
                    <br></br>
                    <a style={{color: 'white'}} target='_blank' rel="noopener noreferrer" href='https://www.sipc.org'>SIPC</a>
                    <br></br>
                    <a style={{color: 'white'}} target='_blank' rel="noopener noreferrer" href='https://www.lpl.com/'>LPL</a>
                </Col>
                
                <Col >
                    <u><strong>Disclosures</strong></u>
                    <br></br>Securities and advisory services are offered through LPL Financial, a registered investment advisor and broker/dealer, Member FINRA/SIPC.
                    This site is designed for U.S. residents only. The services offered within this site are offered exclusively through our U.S. registered representatives. LPL Financial U.S. registered representatives may only conduct business with residents of the states for which they are properly registered: (List of states) Please note that not all of the investments and services mentioned are available in every state.            <br></br>
                    <br></br>
                    The LPL Financial Registered Representatives associated with this site may only discuss and/or transact securities business with residents of the following states: <strong>AZ, AR, IN, LA, MO, OK, TX</strong>
                </Col>
            </Row>
            <br></br>
            <strong>Check the background of your financial professional on FINRA’s <a style={{color: 'blue !important'}} href='https://brokercheck.finra.org/'>BrokerCheck</a>. </strong>
        </Container>
    }
}