import React from 'react'
import {Row, Col, Container} from 'react-bootstrap'

export default class Calculators extends React.Component{

    render(){
        return <Container >
            <br></br>
            <br></br>
            <p>How much retirement income will you need? How much life insurance is enough? What type of IRA is right for you?</p>
            <p>Our financial calculators are designed as educational tools to help you estimate answers to common financial questions. They are not intended to predict future returns or results. Simply click on one of the general financial topics below and you'll find a selection of easy-to-use calculators about related financial topics.</p>
            <p>These calculators are hypothetical examples used for illustrative purposes and do not represent the performance of any specific investment or product. Rates of return will vary over time, particularly for long-term investments. Investments offering the potential for higher rates of return also involve a higher degree of risk of loss. Actual results will vary.</p>
            <br></br>
            <hr></hr>
            <Row>
                <Col >
                    <a target='_blank' rel="noopener noreferrer" href='https://tools.finra.org/retirement_calculator/'><h6>Retirement Calculator</h6></a>
                    <p>Make sure you have enough to see you through your retirement years.</p>
                <br></br>
                    <a target='_blank' rel="noopener noreferrer" href='https://tools.finra.org/max401k/'><h6>401(k) Save the Max Calculator</h6></a>
                    <p>Use this calculator to determine if you are on track to ‘save the max’ in your 401(k).</p>
                <br></br>
                    <a target='_blank' rel="noopener noreferrer" href='https://tools.finra.org/college_savings/'><h6>College Savings Calculator</h6></a>
                    <p>Use our College Savings calculator to determine the amount you must invest each year to have enough money to cover all college costs.</p>
                <br></br>
                    <a target='_blank' rel="noopener noreferrer" href='https://tools.finra.org/accrued_interest/'><h6>Accrued Interest Calculator</h6></a>
                    <p>Interest on a bond accrues between regularly scheduled payments. To find out how much interest is owed on a given bond, use the calculator below. Select the appropriate bond type to figure accrued interest for corporate and municipal bonds or government bonds. Interest for corporate and municipal bonds is calculated using a 360-day year. Interest for government bonds is calculated using a 365-day year.</p>
                </Col>
                
                <Col>
                    <a target='_blank' rel="noopener noreferrer" href='https://tools.finra.org/rmd/'><h6>Required Minimum Distribution Calculator</h6></a>
                    <p>Use this calculator to determine your Required Minimum Distribution (RMD) from a traditional 401(k) or IRA.</p>
                <br></br>
                    <a target='_blank' rel="noopener noreferrer" href='https://tools.finra.org/savings_calculator/'><h6>Savings Calculator</h6></a>
                    <p>Use our savings calculator to see how a consistent approach to investing can make your money grow. Whether saving for a house, a car, or other special purchase, the savings calculator will help you determine the right amount to save on a regular basis to achieve your goal.</p>
                <br></br>
                    <a target='_blank' rel="noopener noreferrer" href='https://tools.finra.org/loan/'><h6>Loan Calculator</h6></a>
                    <p>Use this calculator to determine the monthly payment for a fixed-rate loan. For car loans, determine if a longer term makes sense. Your monthly payment will drop though your total cost will rise. For a mortgage, run it twice to compare two offers or the merits of refinancing. See how much a monthly payment drops by reducing the interest rate by just one half of one percent.</p>
                <br></br>
                </Col>
            </Row>
            <hr></hr>
            <br></br>        
            <br></br>
            <p><strong>Link Disclosure:</strong> This information is being provided strictly as a courtesy.  When you link to any of the websites provided you are actually leaving this website.  We make no representation as to the completeness or accuracy of the information provided on these websites nor is the company liable for any direct or indirect technical or system issues or any consequences arising out of your access to or use of these third-party technologies, websites, information or programs made available through them.</p>
            <br></br>
            <br></br>
            <h6>Source: FINRA - finra.org</h6>
        </Container>
    }
}