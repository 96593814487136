import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default class PersonalFin extends React.Component{

    render(){
        return <Container>
<br></br>
            <p>It's important to know what you want to accomplish with your investments before you actually invest. Learn how to set your financial goals, get a handle on your cash flow, control spending, manage debt, and get on the path to saving and investing.</p>
<br></br>
<hr></hr>
            <Row>
                <Col>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.finra.org/investors/personal-finance/set-your-financial-goals'><h6>Set Your Financial Goals</h6></a>
                    <p>Just as in other aspects of your life, setting financial goals is a tried-and-true way to reach those goals.</p>
                    <br></br>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.finra.org/investors/personal-finance/know-your-net-worth'><h6>Know Your Net Worth</h6></a>
                    <p>Creating a net worth statement, and updating it each year, will help you monitor your financial progress and meet financial goals.</p>
                    <br></br>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.finra.org/investors/personal-finance/control-spending-calculate-cash-flow'><h6>Control Your Spending</h6></a>
                    <p>Calculating your monthly cash flow will help you evaluate your present financial status, so you know where you stand financially as you prepare to invest.</p>
                </Col>
                <Col>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.finra.org/investors/personal-finance/manage-your-debt'><h6>Manage Your Debt</h6></a>
                    <p>As you prepare to invest, take stock of your current debts and learn how to manage them effectively.</p>
                    <br></br>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.finra.org/investors/personal-finance/how-your-credit-score-impacts-your-financial-future'><h6>Understand Your Credit Score</h6></a>
                    <p>Each individual has his or her own credit score but many people do not know about the credit scoring system and how it impacts them.</p>
                    <br></br>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.finra.org/investors/personal-finance/start-emergency-fund'><h6>Start an Emergency Fund</h6></a>
                    <p>If something unexpected happens to you, having the money you need to pay your bills will help to keep you out of debt.</p>
                </Col>
            </Row>
<hr></hr>
<br></br>
            <p><strong>Link Disclosure:</strong> This information is being provided strictly as a courtesy.  When you link to any of the websites provided you are actually leaving this website.  We make no representation as to the completeness or accuracy of the information provided on these websites nor is the company liable for any direct or indirect technical or system issues or any consequences arising out of your access to or use of these third-party technologies, websites, information or programs made available through them.</p>
<br></br>
            <br></br>
            <h6>Source: FINRA - finra.org</h6>
        </Container>
    }
}