import React from 'react'
import NavBar from './NavBar'

export default class PageHeader extends React.Component{

    render(){
        return<div>
            <header className='jumbo-banner'>
                <NavBar home='false' />
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
                <h1>{this.props.title}</h1>
            </header>
        </div>

    }
}