import React from 'react'
import Calculators from './Calculators'
import Helpful from './Helpful'
import PageHeader from './PageHeader'
import PersonalFin from './PersonalFin'
import Tools from './Tools'

export default class Resources extends React.Component{
        renderResource = ()=>{
            switch(this.props.resource){
                case('Calculators'):
                    return <Calculators />
                case('Tools'):
                    return <Tools/>
                case('Personal Finance'):
                    return <PersonalFin/>
                case('Helpful Links'):
                    return <Helpful/>
                default:
                    return <div>'Error'</div>
            }
        }

    render(){
        return <div>
            <PageHeader title={this.props.resource}/>
            {this.renderResource()}
        </div>
    }
}