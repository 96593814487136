import React from 'react'
import {Row, Accordion, Card, Col} from 'react-bootstrap'
import {ClipboardData, Umbrella, Archive, HouseDoor, Book, Briefcase, GraphUp} from 'react-bootstrap-icons'

export default class AccordionService extends React.Component{

render(){
return <div>
    <Row className="justify-content-md-center" style={{width: '97%', display:'block', margin: 'auto'}}>
        <Accordion className='test-service-accord'>
            <Card style={{color: 'white'}}>
                <Card.Header style={{backgroundColor: '#133a6b'}}>
                    <Accordion.Toggle as={Card.Header}  eventKey="0">
                        <Row>
                            <Col md='auto'>
                                <ClipboardData width='75px' height='75px' style={{color: 'white'}}/>  
                            </Col>
                            <Col className='test-accord-title'>
                                <strong><h1>Retirement Planning</h1></strong>
                            </Col >
                        </Row>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body style={{backgroundColor: '#133a6b'}}>Retirement planning is much more than building a nest egg and making sure to not spend that nest egg too fast. A true retirement plan takes much more into consideration. There are two main phases in retirement planning, the accumulation phase and preservation phase. During the accumulation phase, is the time to make sure your assets are invested in an appropriate manner to grow but also protect itself from hectic markets. As you progress through the accumulation phase your investments need to as well. We also need to make sure we are managing our debt and other family responsibilities. During the preservation phase It is time to make sure you and your wealth are protected. This mean formulating income streams and protection from sequence of return risk. This is also a time to start forming a legacy plan. It's important to have a plan that has main contingencies depending on what happens in the market but also your life. These are the plans T.F. develops for all of his clients. He makes sure to walk you through both major phases and make adjustments along the way. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card style={{color: '#133a6b'}}>
                <Card.Header style={{backgroundColor: '#e77725'}}>
                    <Accordion.Toggle as={Card.Header}  eventKey="1">
                        <Row>
                            <Col md='auto'>
                                <Archive width='75px' height='75px' style={{color: '#133a6b'}}/>
                            </Col>
                            <Col className='test-accord-title'>
                                <strong><h1>Tax Management</h1></strong>
                            </Col>
                        </Row>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body style={{backgroundColor: '#e77725'}}>Taxes are a part of life. Learning how to manage taxes is vital for wealth management. Developing tax-efficient investment strategies is something T.F. has been doing for years. T.F. will work hand in hand with your CPA to find the best solution for you. This team approach to managing taxes will help you keep your tax burden under control.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card style={{color: '#0075A2'}}>
                <Card.Header style={{backgroundColor: '#CEDFD9'}}>
                    <Accordion.Toggle as={Card.Header}  eventKey="2">
                        <Row>
                            <Col md='auto'>
                                <HouseDoor width='75px' height='75px' style={{color: '#0075A2'}}/>
                            </Col>
                            <Col className='test-accord-title'>
                                <strong><h1> Estate Planning</h1></strong>
                            </Col>
                        </Row>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body style={{backgroundColor: '#CEDFD9'}}>For some people, the main concern is how to best go about building a nest egg. But for many, there is a desire to ensure their estate delivers benefits beyond their own retirement, and produces a lasting legacy. T.F. utilizes years of experience and strategic partners to insure your legacy continues exactly how you want it to. What does this process look like? T.F. works with estate-planning and trust specialists to help review your wills and trusts. He works to preserve your estate for your intended heirs, establish beneficiary designations, and reduce potential exposure to estate taxes and probate costs. he also coordinates with your tax and legal advisors to make sure you are fully covered.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header style={{backgroundColor: '#EBFCFB'}}>
                    <Accordion.Toggle as={Card.Header}  eventKey="3">
                        <Row>
                            <Col md='auto'>
                                <Book width='75px' height='75px' style={{color: 'black'}}/>
                            </Col>
                            <Col className='test-accord-title'>
                                <strong><h1>Education Funding</h1></strong>
                            </Col>
                        </Row>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body style={{backgroundColor: '#EBFCFB'}}>T.F is a firm believer in the power of education. Education has one of the highest inflation rates in the United States. This mixed with the increasing demand for educated employees make education planning vital. There are many strategies one can take for educational planning. Some clients prefer a tax efficient solution while others prefer more of a family approach. T.F. can walk you through the main options that are available for education planning and help you decide which one is best for you.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card style={{color: '#F0E68C'}}>
                <Card.Header style={{backgroundColor: '#1A936F'}}>
                    <Accordion.Toggle as={Card.Header}  eventKey="4">
                        <Row>
                            <Col md='auto'>
                                <Briefcase width='75px' height='75px' style={{color: '#F0E68C'}}/>
                            </Col>
                            <Col className='test-accord-title'>
                                <strong><h1>Risk Management</h1></strong>
                            </Col>
                        </Row>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body style={{backgroundColor: '#1A936F'}}>Risk management is something T.F. takes very seriously. Risk comes in all shapes and sizes. Often times investors only focus on investment risk. This is something that can be managed with asset allocation and certain risk migration strategies. There are other risk that can derail even the best investment plans if not planned for accordantly. What happens if you lose work, have a major medical event, have a family member that needs some help, lose a key employee, or any of the other 'ifs' in life? There are an arrangement of insurance products that can help with this. T.F.’s holistic approach to risk management will make sure your investments and also your life is protected. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card style={{color: '#00FFFF'}}>
                <Card.Header style={{backgroundColor: '#826251'}}>
                    <Accordion.Toggle as={Card.Header}  eventKey="5">
                        <Row>
                            <Col md='auto'>
                                <GraphUp width='75px' height='75px' style={{color: '#00FFFF'}}/>
                            </Col>
                            <Col className='test-accord-title'>
                                <strong><h1>Investment Planning</h1></strong>
                            </Col>
                        </Row>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body style={{backgroundColor: '#826251'}}>T.F. utilizes his 20+ years of experience and research to help guide his investment planning with clients. An investment plan is more than just picking quality investments. An investment plan needs to have guard rails in place to make sure you know how to act in the moment. The market and the world move fast. With a sound plan, you are able to act when your investment hits a trigger point or if the market crashes. T.F. makes sure that each investment plan is crafted based on each clients investments and risk tolerance. More importantly, T.F. takes the time to explain the why behind each facet of the plan. His goal is too have every client leave felling confident and prepared. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card style={{color: 'white'}}>
                <Card.Header style={{backgroundColor: '#280003'}}>
                    <Accordion.Toggle as={Card.Header}  eventKey="6">
                        <Row>
                            <Col md='auto'>
                                <Umbrella width='75px' height='75px' style={{color: 'white'}}/>
                            </Col>
                            <Col className = 'test-accord-title'>
                                <strong><h1>Employee and Executive Benefits</h1></strong>
                            </Col>
                        </Row>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                    <Card.Body style={{backgroundColor: '#280003'}}>Retirement plans are not just for individuals. There are many options available to businesses of all sizes. Having a retirement plan as a business opens up the door to hire more qualified and loyal employees. Businesses can also save money on taxes with the correct type of plan. T.F. will help walk you through the different options that are available for yours size company.  </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </Row>
</div>
}
}
